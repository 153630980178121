/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `FUTURE` - Future
 * * `NEXT` - Next
 * * `OVERDUE` - Overdue
 * * `PAID` - Paid
 * @export
 * @enum {string}
 */
export enum StateEnum {
    Future = 'FUTURE',
    Next = 'NEXT',
    Overdue = 'OVERDUE',
    Paid = 'PAID'
}


export function instanceOfStateEnum(value: any): boolean {
    for (const key in StateEnum) {
        if (Object.prototype.hasOwnProperty.call(StateEnum, key)) {
            if (StateEnum[key as keyof typeof StateEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function StateEnumFromJSON(json: any): StateEnum {
    return StateEnumFromJSONTyped(json, false);
}

export function StateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StateEnum {
    return json as StateEnum;
}

export function StateEnumToJSON(value?: StateEnum | null): any {
    return value as any;
}

