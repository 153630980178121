/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StrapiMedia } from './StrapiMedia';
import {
    StrapiMediaFromJSON,
    StrapiMediaFromJSONTyped,
    StrapiMediaToJSON,
} from './StrapiMedia';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface StrapiFiles
 */
export interface StrapiFiles {
    /**
     * 
     * @type {string}
     * @memberof StrapiFiles
     */
    customFilename?: string | null;
    /**
     * 
     * @type {StrapiMedia}
     * @memberof StrapiFiles
     */
    file?: StrapiMedia | null;
    /**
     * 
     * @type {string}
     * @memberof StrapiFiles
     */
    filename?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiFiles
     */
    year?: number | null;
}

/**
 * Check if a given object implements the StrapiFiles interface.
 */
export function instanceOfStrapiFiles(value: object): boolean {
    return true;
}

export function StrapiFilesFromJSON(json: any): StrapiFiles {
    return StrapiFilesFromJSONTyped(json, false);
}

export function StrapiFilesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrapiFiles {
    if (json == null) {
        return json;
    }
    return {
        
        'customFilename': json['custom_filename'] == null ? undefined : json['custom_filename'],
        'file': json['file'] == null ? undefined : StrapiMediaFromJSON(json['file']),
        'filename': json['filename'] == null ? undefined : json['filename'],
        'year': json['year'] == null ? undefined : json['year'],
    };
}

export function StrapiFilesToJSON(value?: StrapiFiles | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'custom_filename': value['customFilename'],
        'file': StrapiMediaToJSON(value['file']),
        'filename': value['filename'],
        'year': value['year'],
    };
}

