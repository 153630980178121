/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface IssuePeriod
 */
export interface IssuePeriod {
    /**
     * 
     * @type {Date}
     * @memberof IssuePeriod
     */
    endDate: Date;
    /**
     * 
     * @type {boolean}
     * @memberof IssuePeriod
     */
    isFirstPeriod: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IssuePeriod
     */
    isLastPeriod: boolean;
    /**
     * 
     * @type {Date}
     * @memberof IssuePeriod
     */
    payoutDate: Date;
    /**
     * 
     * @type {string}
     * @memberof IssuePeriod
     */
    period: string;
    /**
     * 
     * @type {Date}
     * @memberof IssuePeriod
     */
    startDate: Date;
    /**
     * 
     * @type {number}
     * @memberof IssuePeriod
     */
    yieldPeriod: number;
}

/**
 * Check if a given object implements the IssuePeriod interface.
 */
export function instanceOfIssuePeriod(value: object): boolean {
    if (!('endDate' in value)) return false;
    if (!('isFirstPeriod' in value)) return false;
    if (!('isLastPeriod' in value)) return false;
    if (!('payoutDate' in value)) return false;
    if (!('period' in value)) return false;
    if (!('startDate' in value)) return false;
    if (!('yieldPeriod' in value)) return false;
    return true;
}

export function IssuePeriodFromJSON(json: any): IssuePeriod {
    return IssuePeriodFromJSONTyped(json, false);
}

export function IssuePeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssuePeriod {
    if (json == null) {
        return json;
    }
    return {
        
        'endDate': (dayjs.tz(json['end_date'], 'YYYY-MM-DD', dayjs.tz.guess()).toDate()),
        'isFirstPeriod': json['is_first_period'],
        'isLastPeriod': json['is_last_period'],
        'payoutDate': (dayjs.tz(json['payout_date'], 'YYYY-MM-DD', dayjs.tz.guess()).toDate()),
        'period': json['period'],
        'startDate': (dayjs.tz(json['start_date'], 'YYYY-MM-DD', dayjs.tz.guess()).toDate()),
        'yieldPeriod': json['yield_period'],
    };
}

export function IssuePeriodToJSON(value?: IssuePeriod | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'end_date': (dayjs(value['endDate']).format('YYYY-MM-DD')),
        'is_first_period': value['isFirstPeriod'],
        'is_last_period': value['isLastPeriod'],
        'payout_date': (dayjs(value['payoutDate']).format('YYYY-MM-DD')),
        'period': value['period'],
        'start_date': (dayjs(value['startDate']).format('YYYY-MM-DD')),
        'yield_period': value['yieldPeriod'],
    };
}

