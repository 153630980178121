/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InterestCalculationMethodEnum } from './InterestCalculationMethodEnum';
import {
    InterestCalculationMethodEnumFromJSON,
    InterestCalculationMethodEnumFromJSONTyped,
    InterestCalculationMethodEnumToJSON,
} from './InterestCalculationMethodEnum';
import type { IssuePeriodPayout } from './IssuePeriodPayout';
import {
    IssuePeriodPayoutFromJSON,
    IssuePeriodPayoutFromJSONTyped,
    IssuePeriodPayoutToJSON,
} from './IssuePeriodPayout';
import type { PeriodicityEnum } from './PeriodicityEnum';
import {
    PeriodicityEnumFromJSON,
    PeriodicityEnumFromJSONTyped,
    PeriodicityEnumToJSON,
} from './PeriodicityEnum';
import type { RatingEnum } from './RatingEnum';
import {
    RatingEnumFromJSON,
    RatingEnumFromJSONTyped,
    RatingEnumToJSON,
} from './RatingEnum';
import type { StrapiCollateral } from './StrapiCollateral';
import {
    StrapiCollateralFromJSON,
    StrapiCollateralFromJSONTyped,
    StrapiCollateralToJSON,
} from './StrapiCollateral';
import type { StrapiDluhopisomatScore } from './StrapiDluhopisomatScore';
import {
    StrapiDluhopisomatScoreFromJSON,
    StrapiDluhopisomatScoreFromJSONTyped,
    StrapiDluhopisomatScoreToJSON,
} from './StrapiDluhopisomatScore';
import type { StrapiHeroSection } from './StrapiHeroSection';
import {
    StrapiHeroSectionFromJSON,
    StrapiHeroSectionFromJSONTyped,
    StrapiHeroSectionToJSON,
} from './StrapiHeroSection';
import type { StrapiIssueFileCategories } from './StrapiIssueFileCategories';
import {
    StrapiIssueFileCategoriesFromJSON,
    StrapiIssueFileCategoriesFromJSONTyped,
    StrapiIssueFileCategoriesToJSON,
} from './StrapiIssueFileCategories';
import type { StrapiIssueReasonsToInvest } from './StrapiIssueReasonsToInvest';
import {
    StrapiIssueReasonsToInvestFromJSON,
    StrapiIssueReasonsToInvestFromJSONTyped,
    StrapiIssueReasonsToInvestToJSON,
} from './StrapiIssueReasonsToInvest';
import type { StrapiIssuer } from './StrapiIssuer';
import {
    StrapiIssuerFromJSON,
    StrapiIssuerFromJSONTyped,
    StrapiIssuerToJSON,
} from './StrapiIssuer';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface Issue
 */
export interface Issue {
    /**
     * 
     * @type {number}
     * @memberof Issue
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    bondForm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    bondResemblance?: string | null;
    /**
     * 
     * @type {Array<StrapiCollateral>}
     * @memberof Issue
     */
    readonly collaterals: Array<StrapiCollateral>;
    /**
     * 
     * @type {StrapiIssueFileCategories}
     * @memberof Issue
     */
    fileCategories?: StrapiIssueFileCategories | null;
    /**
     * 
     * @type {StrapiDluhopisomatScore}
     * @memberof Issue
     */
    readonly dluhopisomatScore: StrapiDluhopisomatScore;
    /**
     * 
     * @type {StrapiHeroSection}
     * @memberof Issue
     */
    heroSection?: StrapiHeroSection | null;
    /**
     * 
     * @type {number}
     * @memberof Issue
     */
    investedAmount: number;
    /**
     * 
     * @type {number}
     * @memberof Issue
     */
    investorsCount: number;
    /**
     * 
     * @type {StrapiIssuer}
     * @memberof Issue
     */
    readonly issuer: StrapiIssuer | null;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    moneyUsagePlan?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    motivation?: string | null;
    /**
     * 
     * @type {Array<IssuePeriodPayout>}
     * @memberof Issue
     */
    payouts: Array<IssuePeriodPayout>;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    projectName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    purpose?: string | null;
    /**
     * 
     * @type {Array<StrapiIssueReasonsToInvest>}
     * @memberof Issue
     */
    readonly reasonsToInvest: Array<StrapiIssueReasonsToInvest>;
    /**
     * 
     * @type {number}
     * @memberof Issue
     */
    remainingAmount: number;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    tagline?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Issue
     */
    readonly tags: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof Issue
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Issue
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {InterestCalculationMethodEnum}
     * @memberof Issue
     */
    interestCalculationMethod?: InterestCalculationMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    interestRate?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Issue
     */
    interestStartDate: Date;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    isin: string;
    /**
     * 
     * @type {Date}
     * @memberof Issue
     */
    issueDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof Issue
     */
    maturityDate: Date;
    /**
     * 
     * @type {string}
     * @memberof Issue
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Issue
     */
    nominalValue: number;
    /**
     * 
     * @type {PeriodicityEnum}
     * @memberof Issue
     */
    periodicity: PeriodicityEnum;
    /**
     * 
     * @type {RatingEnum}
     * @memberof Issue
     */
    rating?: RatingEnum | null;
    /**
     * 
     * @type {Date}
     * @memberof Issue
     */
    termsDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Issue
     */
    totalAmount: number;
}



/**
 * Check if a given object implements the Issue interface.
 */
export function instanceOfIssue(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('collaterals' in value)) return false;
    if (!('dluhopisomatScore' in value)) return false;
    if (!('investedAmount' in value)) return false;
    if (!('investorsCount' in value)) return false;
    if (!('issuer' in value)) return false;
    if (!('payouts' in value)) return false;
    if (!('reasonsToInvest' in value)) return false;
    if (!('remainingAmount' in value)) return false;
    if (!('tags' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    if (!('interestStartDate' in value)) return false;
    if (!('isin' in value)) return false;
    if (!('issueDate' in value)) return false;
    if (!('maturityDate' in value)) return false;
    if (!('name' in value)) return false;
    if (!('nominalValue' in value)) return false;
    if (!('periodicity' in value)) return false;
    if (!('totalAmount' in value)) return false;
    return true;
}

export function IssueFromJSON(json: any): Issue {
    return IssueFromJSONTyped(json, false);
}

export function IssueFromJSONTyped(json: any, ignoreDiscriminator: boolean): Issue {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'bondForm': json['bond_form'] == null ? undefined : json['bond_form'],
        'bondResemblance': json['bond_resemblance'] == null ? undefined : json['bond_resemblance'],
        'collaterals': ((json['collaterals'] as Array<any>).map(StrapiCollateralFromJSON)),
        'fileCategories': json['file_categories'] == null ? undefined : StrapiIssueFileCategoriesFromJSON(json['file_categories']),
        'dluhopisomatScore': StrapiDluhopisomatScoreFromJSON(json['dluhopisomat_score']),
        'heroSection': json['hero_section'] == null ? undefined : StrapiHeroSectionFromJSON(json['hero_section']),
        'investedAmount': json['invested_amount'],
        'investorsCount': json['investors_count'],
        'issuer': StrapiIssuerFromJSON(json['issuer']),
        'moneyUsagePlan': json['money_usage_plan'] == null ? undefined : json['money_usage_plan'],
        'motivation': json['motivation'] == null ? undefined : json['motivation'],
        'payouts': ((json['payouts'] as Array<any>).map(IssuePeriodPayoutFromJSON)),
        'projectName': json['project_name'] == null ? undefined : json['project_name'],
        'purpose': json['purpose'] == null ? undefined : json['purpose'],
        'reasonsToInvest': ((json['reasons_to_invest'] as Array<any>).map(StrapiIssueReasonsToInvestFromJSON)),
        'remainingAmount': json['remaining_amount'],
        'tagline': json['tagline'] == null ? undefined : json['tagline'],
        'tags': json['tags'],
        'createdAt': (dayjs(json['created_at']).toDate()),
        'updatedAt': (dayjs(json['updated_at']).toDate()),
        'interestCalculationMethod': json['interest_calculation_method'] == null ? undefined : InterestCalculationMethodEnumFromJSON(json['interest_calculation_method']),
        'interestRate': json['interest_rate'] == null ? undefined : json['interest_rate'],
        'interestStartDate': (dayjs.tz(json['interest_start_date'], 'YYYY-MM-DD', dayjs.tz.guess()).toDate()),
        'isin': json['isin'],
        'issueDate': (dayjs.tz(json['issue_date'], 'YYYY-MM-DD', dayjs.tz.guess()).toDate()),
        'maturityDate': (dayjs.tz(json['maturity_date'], 'YYYY-MM-DD', dayjs.tz.guess()).toDate()),
        'name': json['name'],
        'nominalValue': json['nominal_value'],
        'periodicity': PeriodicityEnumFromJSON(json['periodicity']),
        'rating': json['rating'] == null ? undefined : RatingEnumFromJSON(json['rating']),
        'termsDate': json['terms_date'] == null ? undefined : (dayjs.tz(json['terms_date'], 'YYYY-MM-DD', dayjs.tz.guess()).toDate()),
        'totalAmount': json['total_amount'],
    };
}

export function IssueToJSON(value?: Issue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bond_form': value['bondForm'],
        'bond_resemblance': value['bondResemblance'],
        'file_categories': StrapiIssueFileCategoriesToJSON(value['fileCategories']),
        'hero_section': StrapiHeroSectionToJSON(value['heroSection']),
        'invested_amount': value['investedAmount'],
        'investors_count': value['investorsCount'],
        'money_usage_plan': value['moneyUsagePlan'],
        'motivation': value['motivation'],
        'payouts': ((value['payouts'] as Array<any>).map(IssuePeriodPayoutToJSON)),
        'project_name': value['projectName'],
        'purpose': value['purpose'],
        'remaining_amount': value['remainingAmount'],
        'tagline': value['tagline'],
        'interest_calculation_method': InterestCalculationMethodEnumToJSON(value['interestCalculationMethod']),
        'interest_rate': value['interestRate'],
        'interest_start_date': (dayjs(value['interestStartDate']).format('YYYY-MM-DD')),
        'isin': value['isin'],
        'issue_date': (dayjs(value['issueDate']).format('YYYY-MM-DD')),
        'maturity_date': (dayjs(value['maturityDate']).format('YYYY-MM-DD')),
        'name': value['name'],
        'nominal_value': value['nominalValue'],
        'periodicity': PeriodicityEnumToJSON(value['periodicity']),
        'rating': RatingEnumToJSON(value['rating']),
        'terms_date': value['termsDate'] == null ? undefined : (dayjs(value['termsDate'] as any).format('YYYY-MM-DD')),
        'total_amount': value['totalAmount'],
    };
}

