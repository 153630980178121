/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IssuePeriod } from './IssuePeriod';
import {
    IssuePeriodFromJSON,
    IssuePeriodFromJSONTyped,
    IssuePeriodToJSON,
} from './IssuePeriod';
import type { StateEnum } from './StateEnum';
import {
    StateEnumFromJSON,
    StateEnumFromJSONTyped,
    StateEnumToJSON,
} from './StateEnum';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface IssuePeriodPayout
 */
export interface IssuePeriodPayout {
    /**
     * 
     * @type {IssuePeriod}
     * @memberof IssuePeriodPayout
     */
    issuePeriod: IssuePeriod;
    /**
     * 
     * @type {string}
     * @memberof IssuePeriodPayout
     */
    period: string;
    /**
     * 
     * @type {StateEnum}
     * @memberof IssuePeriodPayout
     */
    state: StateEnum;
}



/**
 * Check if a given object implements the IssuePeriodPayout interface.
 */
export function instanceOfIssuePeriodPayout(value: object): boolean {
    if (!('issuePeriod' in value)) return false;
    if (!('period' in value)) return false;
    if (!('state' in value)) return false;
    return true;
}

export function IssuePeriodPayoutFromJSON(json: any): IssuePeriodPayout {
    return IssuePeriodPayoutFromJSONTyped(json, false);
}

export function IssuePeriodPayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssuePeriodPayout {
    if (json == null) {
        return json;
    }
    return {
        
        'issuePeriod': IssuePeriodFromJSON(json['issue_period']),
        'period': json['period'],
        'state': StateEnumFromJSON(json['state']),
    };
}

export function IssuePeriodPayoutToJSON(value?: IssuePeriodPayout | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'issue_period': IssuePeriodToJSON(value['issuePeriod']),
        'period': value['period'],
        'state': StateEnumToJSON(value['state']),
    };
}

